.date-filter {
  position: relative;
  width: 100%;
  padding: 3px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .selected-date {
    font-size: 15px;
    color: black;
    border: 1px solid lightgray;
    border-radius: 4px;
    width: 280px;
    display: inline-flex;
    background-color: #f8fafc;
    border-radius: 14px;
    height: 52px;
    padding: 15px;
    align-items: center;
    flex-direction: row;
    font-family: "Roboto", sans-serif;
    justify-content: center;
    cursor: pointer;

    .calendar-logo {
      display: block;
      // width: 20px;
    }
  }

  .selectedDateRangeInputBox {
    display: flex;
    align-items: center;
  }

  .calendar {
    // position: absolute;
    // display: block;
    // left:-143%;
    // z-index: 999999;
  }

  .hide {
    display: none;
  }

  .show {
    display: inline-flex;
    // box-shadow: 0px 2px 15px #888888;
  }

  .rdrDefinedRangesWrapper {
    display: block;
  }

  .rdrCalendarWrapper {
    // box-shadow: 0px 2px 15px #888888;
    position: relative;
    z-index: 1;
  }

  .dateBoxContainer {
    border: 0px solid red;
    position: absolute;
    display: block;
    left: -143%;
    z-index: 999999;
    background: white;
    box-shadow: 0px 2px 15px #888888;
    flex-direction: column;

    .hide {
      display: none;
    }

    .show {
      display: inline-flex;
      // box-shadow: 0px 2px 15px #888888;
    }
  }

  .dateBoxContainer.hide {
    display: none;
  }

  .dateBoxContainer.show {
    display: inline-flex;
  }

  .dateBoxContainerIAP {
    border: 0px solid red;
    position: absolute;
    display: block;
    left: 3%;
    z-index: 999999;
    background: white;
    box-shadow: 0px 2px 15px #888888;
    flex-direction: column;

    .hide {
      display: none;
    }

    .show {
      display: inline-flex;
      // box-shadow: 0px 2px 15px #888888;
    }
  }

  .dateBoxContainerIAP.hide {
    display: none;
  }

  .dateBoxContainerIAP.show {
    display: inline-flex;
  }

  .calendarFooter {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
  }
}

.skan-filter {
  display: inline-flex;
  align-items: center;
  height: 100%;
  width: 100%;
  flex-direction: row;
  justify-content: center;
}

.skan-filter-iap {
  visibility: hidden;
}

.filter-Button {
  display: inline-flex;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: right;
  flex-direction: row;
  justify-content: flex-end;
}

.calendarDisable {
  pointer-events: none;
}

.calendarEnable {
  pointer-events: all;
}

.custom-tooltip {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  padding: 10px;
  color: #333333;
  font-family: Arial, sans-serif;
  max-width: 200px; // Adjust the width as needed
}

.custom-tooltip .tooltip-header {
  background-color: #f0f0f0;
  padding: 5px;
  font-weight: bold;
}

.custom-tooltip .tooltip-content {
  margin-top: 5px;
}

.custom-tooltip .category {
  font-weight: bold;
}

.custom-tooltip .value {
  margin-left: 8px;
}

.performanceDataContainer {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 17%;
}

.campaignDataContainer {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 0%;
  min-height: 167px;
  height: 100%;
}

.topPerformerDataContainer {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 4%;
  min-height: 171px;
  height: 100%;
}

.campaign_name_settings {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  // overflow: scroll;
  overflow-wrap: break-word;
  white-space: pre-wrap;
}

.country_name_settings {
  white-space: pre-wrap;
  overflow-wrap: break-word;
  width: 100%;
  text-align: center;
}

.custom-tooltip-scroll {
  padding: 10px;
  background: white;
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
}

.custom-datagrid .MuiDataGrid-row {
  font-size: 12px;
  /* Adjust the font size as needed */
}

.custom-datagrid .MuiDataGrid-cell--textLeft {
  padding-top: 10px;
  padding-bottom: 10px;
}

.custom-datagrid .MuiDataGrid-cell--textCenter {
  padding-top: 5px;
  padding-bottom: 5px;
}

.custom-datagrid .MuiDataGrid-columnHeader {
  height: 56px;
  width: 100px;
  min-width: 100px;
  max-width: 100px;
}

.reportMainContainer {
  width: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.eventMainData {
  width: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.custom-datagrid {
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* Add smooth scrolling behavior */
.custom-datagrid .MuiDataGrid-main {
  scroll-behavior: smooth;
}

.custom-datagrid .MuiDataGrid-virtualScroller::-webkit-scrollbar {
  height: 8px;
  /* Height of the horizontal scrollbar */
}

.custom-datagrid .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  /* Scrollbar thumb color */
  border-radius: 10px;
}

.custom-datagrid .MuiDataGrid-virtualScroller {
  overflow: auto !important;
  /* Force showing scrollbar */
}

.custom-datagrid {
  // min-width: 1200px;
  /* Ensure it's wide enough to cause scrolling */
  width: 100%;
}